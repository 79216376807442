import React from 'react'
import styled, { keyframes } from 'styled-components'
import Progress from 'components/progress'
import Timeline from 'sections/about/parts/Timeline'

class TabsPart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "skills"
        }
    }

    render() {
        const TabContainer = styled.div`
            min-height: 400px;
            margin-top: 20px;
            @media (max-width:767px) {
                margin-top: 50px;
                padding: 0 20px;
            }
        `
        const TabSelectors = styled.div`
            display: flex;
        `
        
        const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `
        const TabSelector = styled.button`
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            margin: 0 10px 0 0;
            border-bottom: 2px solid #fff;
            transition: .5s;
            &:hover, &.active {
                animation: ${ColorAnimation} 10s infinite alternate;
            }
            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        `

        const Tabs = styled.div`
            margin-top: 20px;
        `

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `

        const Tab = styled.div`
            display: none;
            animation: ${Fade} 1s forwards;
        `

        return(
            <TabContainer>
                <TabSelectors>
                    <TabSelector className={this.state.tab === "skills" ? "active" : ""} onClick={() => this.setState({tab: "skills"})}>
                        Skills
                    </TabSelector>
                    <TabSelector className={this.state.tab === "experience" ? "active" : ""} onClick={() => this.setState({tab: "experience"})}>
                        Experience
                    </TabSelector>
                    <TabSelector className={this.state.tab === "education" ? "active" : ""} onClick={() => this.setState({tab: "education"})}>
                        Education
                    </TabSelector>
                </TabSelectors>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "skills" ? "block" : "none"
                    }}>
                        <Progress value={30} text="React" />
                        <Progress value={30} text="React hooks" />
                        <Progress value={30} text="Redux/Redux toolkit" />
                        <Progress value={30} text="NodeJS/GraphQL" />
                        <Progress value={30} text="Javascript/ES6/ES7" />
                        <Progress value={30} text="UX/UI Develop" />
                        <Progress value={30} text="Figma" />
                        <Progress value={30} text="Browserstack" />
                    </Tab>
                </Tabs>
                <Tabs>
                    <Tab style={{
                            display: this.state.tab === "experience" ? "block" : "none"
                        }}>
                        <Timeline data={{
                                "08/2016 - 03/2017" :  {
                                    title: "Fresher React Developer",
                                    institution: "Enable Startup",
                                    description: "Enable Startup is a new company outsourcing at Da Nang City, hey specialize in receiving and developing projects from abroad such as Japanese, Singapore..."
                                },
                                "04/2017 - 06/2018" : {
                                    title: "Junior Frontend Developer",
                                    institution: "Socialloft",
                                    description: "Socialloft is a product company. Their main product is a social network similar to Facebook is MooSocial."
                                },
                                "07/2018 - 12/2019" : {
                                    title: "Senior React Developer",
                                    institution: "ITL - Logtechub",
                                    description: "ITL is positioned to be the premier regional solutions provider for Integrated Logistics, Aviation Services, Warehousing, Freight Management and Distribution."
                                },
                                "01/2020 - Now" : {
                                    title: "Senior Frontend Developer",
                                    institution: "JSlancer",
                                    description: "In this time, I take projects from abroad and work with my team, and build my own products on npm."
                                }
                            }
                        }
                    />
                    </Tab>
                    <Tab style={{
                            display: this.state.tab === "education" ? "block" : "none"
                        }}>
                        <Timeline data={{
                                "2013 - 2017" : {
                                    title: "College Degree",
                                    institution: "Quang Nam University",
                                    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting"
                                },
                                "2015 - 2017" : {
                                    title: "Bachelor of Information Technology",
                                    institution: "Quang Nam University",
                                    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting"
                                },
                                "2016 - 2017" : {
                                    title: "Web Programming Engineer",
                                    institution: "Vinaenter Academy",
                                    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting"
                                },
                            }
                        }
                    />
                    </Tab>
                </Tabs>
            </TabContainer>
        )
    }
}

export default TabsPart