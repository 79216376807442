import React from 'react'
import styled, { keyframes } from 'styled-components'


class ProgressBar extends React.Component {

    render() {
        const { text } = this.props

        const ProgressContainer = styled.div`
            margin-bottom: 25px;
        `
        
        const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `

        const Text = styled.span`
            font-size: 17px;
            font-family: Poppins;
            color: #fff;
            border-bottom: 3px solid;
            transition: 2s;
            animation: ${ColorAnimation} 10s infinite alternate;
        `

        // const Value = styled.span`
        //     font-size: 17px;
        //     font-family: Poppins;
        //     color: #fff;
        //     float: right;
        // `


        // const Progress = styled.div`
        //     height: 5px;
        //     border-radius: 2.5px;
        //     margin-top: 10px;
        //     transition: 2s;
        //     animation: ${ColorAnimation} 10s infinite alternate;
        // `

        return(
            <ProgressContainer>
                <Text>{text}</Text>
                {/* <Value>{this.props.value}%</Value>
                <Progress style={{width: `${this.props.value}%`}}></Progress> */}
            </ProgressContainer>
        )
    }
}

export default ProgressBar