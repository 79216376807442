import React from 'react'
import styled from 'styled-components'

class ContactThreeForm extends React.Component {

    formSubmit() {
      window.open('https://calendly.com/leophan/15min', '_blank');
    }
    
    shouldComponentUpdate(nextProps, nextState) { 
      return false;
    }

    render() {

        const Separator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 30px;
            margin-left: 6px;
        `

        const ContactForm = styled.div`
            padding: 40px;
            width: 100%;
            position: relative;
            z-index: 5;
        `

        const Heading = styled.h2`
            font-size: 70px;
            font-family: Teko;
            color: #fff;
        `

        const Description = styled.p`
            color: #fff;

            a {
              font-weight: bold;
              text-decoration: none;
              color: #04e5e5;
            }
        `;

        const Submit = styled.button`
            display: block;
            height: 50px;
            width: 250px;
            position: relative;
            border: none;
            overflow: hidden;
            transition: .5s;
            background-color: #000;
            border: 1px solid #fff;
            border-radius: 5px;
            &:hover {
              background: #444;
            }
            span {
              position: relative;
              top: 0;
              color: #fff;
              z-index: 10;
              text-transform: uppercase;
              letter-spacing: 2px;
            }
        `

        return(
          <ContactForm>
              <Heading>
                Get In Touch
              </Heading>
              <Separator />
              <Description>
                I provide 30 mins of free consultant. Click on the button beblow
                to schedule a meeting with me. If you have some question and you
                want to answer quickly, feel free to send me an email at{' '}
                <a href="mailto:leo.phan@jslancer.com">leo.phan@jslancer.com</a>
              </Description>
              <Submit onClick={() => this.formSubmit()}>
                <span>
                  Schedule a meeting
                </span>
              </Submit>
          </ContactForm>
        )
    }

}

export default ContactThreeForm